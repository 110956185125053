.project-entry {
    width: 45rem;
    justify-content: space-between;
    margin: 3rem;
}

@media only screen and (max-width: 800px) {
    .project-entry {
        width: initial;
    }
}

.project-info {
    color: white;
}

.project-name {
    font-size: 1.8rem;
    line-height: 1.5;
}

.project-desc {
    font-family: 'RedHatText';
    font-size: 1.2rem;
    line-height: 1.5;
}

.project-readmore {
    font-size: 0.8rem;
    line-height: 1.5;
    cursor: pointer;
    text-decoration: underline;
    color: #a5a5a5;
}

.project-readmore-disabled{
    cursor: not-allowed !important;
}

.project-btns {
    color: white;
    font-weight: 400;
    align-items: center;
}

.btn {
    align-content: center;
    cursor: pointer;
    width: 5.5rem;
    height: 2rem;
    margin: 0.5rem;
}

.btn-content {
    justify-content: space-between;
    margin: 0.3rem;
}

.btn>div>img {
    height: 1.5rem;
}

.open-btn {
    background-color: #009e7b;
}

.open-btn:hover {
    background-color: #01886b;
}

.source-btn {
    background-color: #0066cc;
}

.source-btn:hover {
    background-color: #015ebb;
}

.disabled-btn {
    background-color: #484949;
    cursor: not-allowed !important;
}