* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'RedHatDisplay';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'RedHatDisplay';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('assets/fonts/RedHatDisplayVF.ttf') format('truetype');
}

@font-face {
  font-family: 'RedHatText';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('assets/fonts/RedHatTextVF.ttf') format('truetype');
}

.display-flex-row {
  display: flex;
  flex-direction: row;
}

.display-flex-col {
  display: flex;
  flex-direction: column;
}

.content {
  width: 100vw;
  margin-top: 2rem;
  align-items: center;
  animation: fade-in 0.6s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}