.article-entry {
    align-items: flex-start;
    color: white;
    margin-bottom: 2.5rem;
}

.article-date {
    line-height: 1.3;
    font-size: 0.8rem;
    color: #a5a5a5;
}

.article-title {
    text-decoration: underline;
    font-size: 1.8rem;
    line-height: 1.5;
    cursor: pointer;
}

.article-title:hover {
    color: #00beff;
}

.article-summary {
    font-family: 'RedHatText';
    font-size: 1.3rem;
    line-height: 1.5;
}

@media only screen and (max-width: 800px) {
    .article-entry {
        margin-left: 2rem;
        margin-right: 2rem;

    }
}