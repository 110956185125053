.brand {
    background-color: #313131;
    opacity: 57.5;
    border-radius: 50rem;
    height: 4rem;
    width: 13rem;
    align-items: center;
}

.brand-img {
    height: 4rem;
}

.brand-img>img {
    height: 4rem;
    border-radius: 50rem;
}

.brand-name {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.header-font {
    font-weight: 400;
    font-size: 1.8rem;
    color: white;
}

.navitem-name:hover{
    cursor: pointer;
    color: rgb(210, 210, 210) !important;
}

.nav-link{
    text-decoration: none;
    color: white;
}

.bottom-bar {
    margin-top: 0.3rem;
    width: 100%;
    height: 0.4rem;
    will-change: width;
}

.bb-selected{
    background-color: #00beff;
    animation: grow 0.8s ease-in forwards;
}


@keyframes grow {
    0% {
        width: 0%;
    }

    100% {
        width: 100% !important;
    }
}

.navbar {
    margin: 1rem;
    flex-grow: 1;
    justify-content: space-between;
    height: 6rem;
}

.navitems {
    align-items: center;
    justify-content: space-around;
    width: 24rem;
    height: 4rem;
}

.empty {
    width: 15rem;
}

@media only screen and (max-width: 800px) {

    .brand,
    .empty {
        display: none;
    }

    .header-font {
        font-size: 1.5rem;
    }
}