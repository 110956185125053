.user-header {
    justify-content: center;
    align-items: center;
}

.profile-image>img {
    height: 5rem;
    width: 5rem;
    border-radius: 4rem;
}

.user-name {
    font-size: 1.8rem;
    font-weight: 400;
    margin-left: 1rem;
}

.tech-icon>img {
    width: 3.5rem;
    height: 3.5rem;
}

.tech-icons {
    margin-top: 2.5rem;
    justify-content: space-evenly;
}

.work-entry {
    font-size: 1.8rem;
    justify-content: space-evenly;
}

.work-designation {
    width: 10rem;
}

.work-place {
    width: 10rem;
    font-family: 'RedHatText';
    text-decoration: underline;
    justify-content: center;
    cursor: pointer;
    overflow-wrap: anywhere;
}

.work-place:hover {
    color: #00beff;
}

.work-list {
    margin-top: 2rem;
    justify-content: center;
}

.user-title {
    margin-top: 2rem;
    font-size: 1.8rem;
    justify-content: center;
    align-items: center;
    overflow-wrap: break-word;
}

.sep-line-container {
    justify-content: center;
}

.separator-line {
    margin-top: 2rem;
    height: 0.2rem;
    width: 20rem;
    background-color: #8b8b90;
}

@media only screen and (max-width: 850px) {
    .user-title {
        font-size: 1.5rem;
    }

    .work-entry {
        margin-bottom: 0.5rem;
    }

    .work-entry>span {
        font-size: 1.4rem;
    }

    .work-designation {
        width: 6rem;
    }

    .work-place {
        justify-content: flex-start;
        width: 6rem;
    }
}